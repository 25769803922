import { UNIT_DETAILS, UTILITY_TYPES } from 'static/utility-units'

import { CONVERSION_ITEMS } from './constants'
import features from './Feature/config'

const sharedSubFields = (title, utility) => {
  const fields = [
    {
      name: `${title} Average Usage`,
      value: `utility.${utility}.totalUsage`
    },
    {
      name: `${title} Average Cost`,
      value: `utility.${utility}.totalUsageCost`
    },
    {
      name: `${title} Cost Per Square Foot`,
      value: `utility.${utility}.costPerSquareFoot`
    },
    {
      name: `${title} Days in Period`,
      value: `utility.${utility}.daysInPeriod`
    },
    {
      name: `${title} Average Daily`,
      value: `utility.${utility}.avgDaily`
    },
    {
      name: `${title} Average Annual Energy`,
      value: `utility.${utility}.averageAnnualEnergy`
    },
    {
      name: `${title} Average Annual Cost`,
      value: `utility.${utility}.averageAnnualCost`
    }
  ]
  if (utility !== UTILITY_TYPES.WATER) {
    fields.push({
      name: `${title} GHG Emissions`,
      value: `utility.${utility}.ghgEmissions`
    })
  }
  return fields
}

const sharedTargetFields = (title, utility) => {
  const fields = [
    {
      name: `${title} Target Reduction (%)`,
      value: `target.${utility}.reductionPercentage`
    },
    {
      name: `${title} Usage Reduction`,
      value: `target.${utility}.usageReduction`
    },
    {
      name: `${title} Cost Reduction`,
      value: `target.${utility}.costReduction`
    }
  ]
  if (utility !== UTILITY_TYPES.WATER) {
    fields.push({
      name: `${title} GHG Emissions Reduction`,
      value: `target.${utility}.ghgEmissionReduction`
    })
  }
  return fields
}

const electricitySubFields = (title, utility) => [
  {
    name: `${title} Average Demand`,
    value: `utility.${utility}.totalDemand`
  },
  {
    name: `${title} Average Demand Cost`,
    value: `utility.${utility}.totalDemandCost`
  },
  {
    name: `${title} Average Max Demand Value`,
    value: `utility.${utility}.totalDemandMonthly`
  }
]

export const UtilityFields = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    let dataPoints = {
      name: title,
      value: utility,
      subFields: [...sharedSubFields(title, utility)]
    }
    if (utility === UTILITY_TYPES.ELECTRICITY) {
      dataPoints.subFields.push(...electricitySubFields(title, utility))
    }
    return dataPoints
  }
)

export const UtilityFieldsWithTargets = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    let dataPoints = {
      name: title,
      value: utility,
      subFields: [
        ...sharedSubFields(title, utility),
        ...sharedTargetFields(title, utility)
      ]
    }
    if (utility === UTILITY_TYPES.ELECTRICITY) {
      dataPoints.subFields.push(...electricitySubFields(title, utility))
    }
    return dataPoints
  }
)

const blendRateSubFields = Object.entries(UNIT_DETAILS).map(
  ([utility, { title }]) => {
    return {
      name: `${title} Average Blended Rate`,
      value: `utility.rates.${utility}`
    }
  }
)

const ghgEmissionConversionFields = Object.entries(CONVERSION_ITEMS).reduce(
  (accum, current) => {
    const item = {
      name: current[1].label,
      value: `utility.ghgEmissions.${current[0]}`
    }
    accum.push(item)
    return accum
  },
  []
)

export const OverviewFields = [
  {
    name: 'Summary',
    value: 'summary',
    subFields: [
      { name: 'Average Cost', value: 'utility.summary.totalCost' },
      { name: 'Average Energy Cost', value: 'utility.summary.totalEnergyCost' },
      {
        name: 'Average Cost per Square Foot',
        value: 'utility.summary.totalCostPerSquareFoot'
      },
      {
        name: 'Average Energy Cost Intensity (ECI)',
        value: 'utility.summary.totalEnergyCostIntensity'
      },
      { name: 'Average Energy Use', value: 'utility.summary.totalEnergyUse' },
      { name: 'Site EUI (kBtu/ft²)', value: 'utility.summary.siteEui' },
      { name: 'Water WUI (ccf/ft²)', value: 'utility.summary.waterWui' },
      {
        name: 'CBECS/RECS Median Electricity EUI (kWh/ft²)',
        value: 'utility.summary.cbecsMedianElectricityEUI'
      },
      {
        name: 'CBECS/RECS Median Natural Gas EUI (therms/ft²)',
        value: 'utility.summary.cbecsMedianNaturalGasEUI'
      },
      {
        name: 'CBECS/RECS Median EUI - All Sources (kBtu/ft²)',
        value: 'utility.summary.cbecsMedianEUIAllSources'
      },
      {
        name: 'Energy Use Target',
        value: 'targetTotals.energyUseTarget'
      },
      {
        name: 'Energy Cost Target',
        value: 'targetTotals.energyCostTarget'
      },
      {
        name: 'Utility Cost Target',
        value: 'targetTotals.utilityCostTarget'
      },
      {
        name: 'GHG Emission Target',
        value: 'targetTotals.ghgEmissionTarget'
      }
    ]
  },
  ...UtilityFieldsWithTargets,
  {
    name: 'Other Fuel',
    value: 'otherFuel',
    subFields: [
      {
        name: 'Other EUI (kWh/ft²)',
        value: 'utility.otherFuel.waterWUI'
      },
      { name: 'Other Average Usage', value: 'utility.otherFuel.totalUsage' },
      {
        name: 'Other Average Usage Percent',
        value: 'utility.otherFuel.totalUsagePercent'
      },
      {
        name: 'Other Average Usage Cost',
        value: 'utility.otherFuel.totalUsageCost'
      },
      {
        name: 'Other Average Usage Cost Percent',
        value: 'utility.otherFuel.totalUsageCostPercent'
      },
      {
        name: 'Other Target Reduction (%)',
        value: `target.${UTILITY_TYPES.OTHER}.reductionPercentage`
      },
      {
        name: 'Other Usage Reduction',
        value: `target.${UTILITY_TYPES.OTHER}.usageReduction`
      },
      {
        name: 'Other Cost Reduction',
        value: `target.${UTILITY_TYPES.OTHER}.costReduction`
      },
      {
        name: 'Other GHG Emissions Reduction',
        value: `target.${UTILITY_TYPES.OTHER}.ghgEmissionReduction`
      }
    ]
  },
  {
    name: 'Rates',
    value: 'rates',
    subFields: blendRateSubFields
  },
  {
    name: 'GHG Emissions',
    value: 'ghgEmissions',
    subFields: [
      {
        name: 'Average GHG Emissions (mtCO2e)',
        value: 'utility.ghgEmissions.totalEmissions'
      },
      {
        name: 'GHG Intensity (kgCO2e/ft²)',
        value: 'utility.ghgEmissions.ghgIntensity'
      },
      ...ghgEmissionConversionFields
    ]
  },
  {
    name: 'Portfolio Manager',
    value: 'portfolioManager',
    subFields: [
      {
        name: 'Latest Energy Star Portfolio Manager Score”',
        value: 'utility.portfolioManager.score'
      }
    ]
  },
  {
    name: 'Annual Cost Benchmark',
    value: 'annualCostBenchmark',
    subFields: [
      {
        name: 'CBECS/RECS Energy Cost - Your Building',
        value: 'utility.annualCostBenchmark.you'
      },
      {
        name: 'CBECS/RECS Energy Cost - % vs Median',
        value: 'utility.annualCostBenchmark.percentMedian'
      },
      {
        name: 'CBECS/RECS Energy Cost - Median',
        value: 'utility.annualCostBenchmark.median'
      },
      {
        name: 'CBECS/RECS Energy Cost - 75th Percentile',
        value: 'utility.annualCostBenchmark.percentile75'
      },
      {
        name: 'CBECS/RECS Energy Cost - 90th Percentile',
        value: 'utility.annualCostBenchmark.percentile90'
      }
    ]
  },
  {
    name: 'Annual Usage Benchmark',
    value: 'annualUsageBenchmark',
    subFields: [
      {
        name: 'CBECS/RECS Energy Use - Your Building',
        value: 'utility.annualUsageBenchmark.you'
      },
      {
        name: 'CBECS/RECS Energy Use - % vs Median',
        value: 'utility.annualUsageBenchmark.percentMedian'
      },
      {
        name: 'CBECS/RECS Energy Use - Median',
        value: 'utility.annualUsageBenchmark.median'
      },
      {
        name: 'CBECS/RECS Energy Use - 75th Percentile',
        value: 'utility.annualUsageBenchmark.percentile75'
      },
      {
        name: 'CBECS/RECS Energy Use - 90th Percentile',
        value: 'utility.annualUsageBenchmark.percentile90'
      }
    ]
  },
  {
    name: 'Degree Days',
    value: 'degree',
    subFields: [
      { name: 'HDD', value: 'utility.degree.Hdd' },
      { name: 'CDD', value: 'utility.degree.Cdd' }
    ]
  }
]
export const EndUseFields = [
  {
    name: 'CBECS/RECS',
    value: 'estimatedEndUseBreakdown',
    subFields: [
      {
        name: 'CBECS/RECS Percentages',
        value: 'estimatedEndUseBreakdown.percentage'
      }
    ]
  },
  {
    name: 'ASHRAE Level II',
    value: 'actualEndUseBreakdown',
    subFields: [
      {
        name: 'ASHRAE Level II Total Use (kBtu)',
        value: 'actualEndUseBreakdown.totalUse'
      },
      {
        name: 'ASHRAE Level II Electricity Use (kWh)',
        value: 'actualEndUseBreakdown.electricity.energyUse'
      },
      {
        name: 'ASHRAE Level II Natural Gas Use (therms)',
        value: 'actualEndUseBreakdown.naturalGas.energyUse'
      },
      {
        name: 'ASHRAE Level II Percentages',
        value: 'actualEndUseBreakdown.totalCost'
      }
    ]
  }
]

export const ContactFields = [
  { name: 'Title', value: 'title' },
  { name: 'First Name', value: 'firstName' },
  { name: 'Last Name', value: 'lastName' },
  { name: 'Company', value: 'company' },
  { name: 'Role', value: 'role' },
  { name: 'Phone Number', value: 'phoneNumber' },
  { name: 'Email Address', value: 'emailAddress' },
  { name: 'Qualification', value: 'qualification' },
  { name: 'Certificate Number', value: 'certificateNumber' },
  { name: 'Expiration Date', value: 'expirationDate' },
  { name: 'Years Of Experience', value: 'yearsOfExperience' }
]

export const DataUserFields = [
  { name: 'Name', value: 'user.name' },
  { name: 'Email', value: 'user.email' },
  { name: 'Current Organization', value: 'user.currentOrganizationName' },
  { name: 'Phone Number', value: 'user.phoneNumber' },
  { name: 'Bio', value: 'user.bio' }
]

export const DataBuildingFields = [
  { name: 'Building Name', value: 'building.buildingName' },
  { name: 'Primary Use', value: 'building.buildingUse' },
  { name: 'Open 24/7', value: 'building.open247' },
  { name: 'Year Built', value: 'building.buildYear' },
  { name: 'Number of Above Grade Floors', value: 'building.floorCount' },
  {
    name: 'Number of Below Grade Floors',
    value: 'building.belowGradeFloorCount'
  },
  { name: 'Square Footage', value: 'building.squareFeet' },
  { name: 'Country', value: 'building.location.country' },
  { name: 'Address', value: 'building.location.address' },
  { name: 'City', value: 'building.location.city' },
  { name: 'State/Province', value: 'building.location.state' },
  { name: 'Postal Code', value: 'building.location.zipCode' },
  { name: 'Client', value: 'building.clientName' },
  { name: 'Site', value: 'building.siteName' },
  { name: 'Borough', value: 'building.nycFields.borough' },
  { name: 'Block', value: 'building.nycFields.block' },
  { name: 'Tax Lot', value: 'building.nycFields.taxLot' },
  { name: 'BIN', value: 'building.nycFields.bin' },
  { name: 'EER', value: 'building.nycFields.eer' },
  { name: 'Building Use List', value: 'building.useList' },
  { name: 'Use List with Square Footage', value: 'building.listSquareFeet' }
]

const equivalencyMeasureFields = Object.entries(CONVERSION_ITEMS).reduce(
  (accum, current) => {
    const item = {
      name: current[1].equivalency_label,
      value: `measure.${current[0]}`
    }
    accum.push(item)
    return accum
  },
  []
)
export const MeasureFields = [
  { name: 'Measure Cost', value: 'measure.project_cost' },
  { name: 'Status', value: 'measure.status' },
  { name: 'Type', value: 'measure.type' },
  { name: 'Total Budget', value: 'measure.totalBudget' },
  { name: 'Annual Savings', value: 'measure.annual_savings' },
  { name: 'Energy Savings', value: 'measure.energy_savings' },
  { name: 'Electric Savings', value: 'measure.electric_savings' },
  { name: 'Natural Gas Savings', value: 'measure.gas_savings' },
  { name: 'Water Savings', value: 'measure.water_savings' },
  { name: 'GHG Savings', value: 'measure.ghg' },
  { name: 'GHG Cost', value: 'measure.ghg_cost' },
  { name: 'Demand Savings', value: 'measure.demand' },
  { name: 'Simple Payback', value: 'measure.simple_payback' },
  { name: 'Payback Period Range', value: 'measure.simple_payback_range' },
  { name: 'Total Incentive', value: 'measure.incentive' },
  { name: 'Sales Margin Equivalency', value: 'measure.sales_margin' },
  { name: 'ROI', value: 'measure.roi' },
  { name: 'NPV', value: 'measure.npv' },
  { name: 'SIR', value: 'measure.sir' },
  { name: 'First Year Cost', value: 'measure.first_year_cost' },
  {
    name: 'Net Measure Cost after Incentive',
    value: 'measure.net_project_cost'
  },
  { name: 'Current Energy Use', value: 'measure.current_energy_use' },
  { name: 'Proposed Energy Use', value: 'measure.proposed_energy_use' },
  {
    name: 'Proposed Building Energy Usage',
    value: 'measure.proposed_energy_usage'
  },
  {
    name: 'Proposed Building Energy Cost',
    value: 'measure.proposed_building_energy_cost'
  },
  { name: 'Current Electricity Use', value: 'measure.current_electricity_use' },
  {
    name: 'Current Electricity Cost',
    value: 'measure.current_electricity_cost'
  },
  {
    name: 'Proposed Electricity Use',
    value: 'measure.proposed_electricity_use'
  },
  {
    name: 'Proposed Electricity Cost',
    value: 'measure.proposed_electricity_cost'
  },
  {
    name: 'Proposed Building Electricity Usage',
    value: 'measure.proposed_electricity_usage'
  },
  {
    name: 'Proposed Building Electricity Cost',
    value: 'measure.proposed_building_electricity_cost'
  },
  { name: 'Current Natural Gas Use', value: 'measure.current_natural_gas_use' },
  {
    name: 'Current Natural Gas Cost',
    value: 'measure.current_natural_gas_cost'
  },
  {
    name: 'Proposed Natural Gas Use',
    value: 'measure.proposed_natural_gas_use'
  },
  {
    name: 'Proposed Natural Gas Cost',
    value: 'measure.proposed_natural_gas_cost'
  },
  {
    name: 'Proposed Building Natural Gas Usage',
    value: 'measure.proposed_natural_gas_usage'
  },
  {
    name: 'Proposed Building Natural Gas Cost',
    value: 'measure.proposed_building_natural_gas_cost'
  },
  { name: 'Current Water Use', value: 'measure.current_water_use' },
  { name: 'Current Water Cost', value: 'measure.current_water_cost' },
  { name: 'Proposed Water Use', value: 'measure.proposed_water_use' },
  { name: 'Proposed Water Cost', value: 'measure.proposed_water_cost' },
  {
    name: 'Proposed Building Water Usage',
    value: 'measure.proposed_water_usage'
  },
  {
    name: 'Proposed Building Water Cost',
    value: 'measure.proposed_building_water_cost'
  },
  { name: 'Proposed GHG emissions', value: 'measure.proposed_ghg_emissions' },
  ...(features.espmEpicFeature
    ? [
        {
          name: 'New Energy Star Score',
          value: 'measure.new_energy_star_score'
        },
        {
          name: 'Energy Star Score Impact',
          value: 'measure.energy_star_score_impact'
        }
      ]
    : []),
  ...equivalencyMeasureFields
]

export const ReportingFields = [
  { name: "Today's Date", value: 'report.createdDate' },
  { name: 'Start Date', value: 'report.startDate' },
  { name: 'End Date', value: 'report.endDate' }
]
